import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setRequestInProgress, setRequestFailure, setRequestInit } from "../../../redux/Slices/RequestSlice";
import { useDispatch, useSelector } from "react-redux";

import {
    Card, Table, Stack, Button, Popover, MenuItem, TableBody, TableContainer, TablePagination, OutlinedInput, InputAdornment, Tooltip, FormControl
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import AlertDialog from "../../../common/AlertDialog";
import Paper from "@mui/material/Paper";
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
import { NewsListHead } from "../../../sections/@dashboard/news";
import useSearchDebounce from 'src/utils/hooks/textDebounce';
import { checkTokenExpiry } from "../../../utils/helpers/functions";
import { createNotification } from "src/common/createNotification";

import { deleteRequest, getRequest, postRequest } from "src/app/httpClient/axiosClient";
import { updateArchievedStatus } from "src/api/archievedList";
import { GENERAL_SETTINGS } from 'src/app/config/endpoints';


import CommentItem from "./commentitem";

function CommentListing(props) {
    const { loader, errorMessage, successMessage } = useSelector(state => state.serviceRequest);
    const { setloading } = props;
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const order = "asc";
    const orderBy = "name";

    const [pageNo, setPageNo] = useState(0);
    const [progressLoading, setProgressLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchText, setSearchText] = useState('');
    const [searchTextDebounce, setSearchTextDebounce] = useSearchDebounce();
    const [commentList, setCommentList] = useState([]);
    const [totalNewsCount, setTotalNewsCount] = useState(0);
    const [open, setOpen] = useState(null);
    const [hoveredNewsId, setHoveredNewsId] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [srNo, setSrNo] = useState(1);

    const handleOpenMenu = (event, id) => {
        setOpen(event.currentTarget);
        setHoveredNewsId(id);
    };

    const handleCloseMenu = () => {
        setOpen(null);
        // setHoveredNewsId(null);
    };

    const handleRouteTo = (path) => {
        navigate(path);
    };

    const handleClickOpen = () => {
        setModalShow(true);
    };

    const handlePublished = async (STATUS, ID) => {
        const _STATUS = STATUS ? 1:0
        const _ENDPONIT = `${GENERAL_SETTINGS.UPDATE_COMMENT_STATUS}?id=${ID}&status=${_STATUS}`;
        try {
            setloading(true)
            const { status, data: { data } } = await getRequest( _ENDPONIT );
            //console.log({ status, data });
            setloading(false);
            createNotification("success", "Update", "Status updated successfully.");
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            createNotification("error", "Update", "Getting error while updating status. Please try  again.");
        }
    };

    const deleteCommentById = async () => {      
        try {
            setOpen(null);
            setloading(true);
            const { status, data: { data } } = await deleteRequest( GENERAL_SETTINGS.DELETE_COMMENT.replace('{ID}', hoveredNewsId) );
            setloading(false);
            createNotification("success", "Success", `Comment was successfully deleted.`);
            getCommentList();
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            createNotification("error", "Update", "Getting error while deleting comment. Please try  again.");
        }
    };
    
    async function getCommentList() {
        const _ENDPONIT = `${GENERAL_SETTINGS.GET_COMMENT}?page=1&limit=100`;
        const _pageNo = pageNo + 1;
        try {
            dispatch(setRequestInProgress());
            const response = await getRequest(_ENDPONIT);
            //console.log(response);
            const { status, data: { data } } = response;
            if (status === 200) {
                setCommentList(data.comments);
                console.log("response ==> ", data);
                dispatch(setRequestInit());
            } else {}
        } catch (error) {
            dispatch(setRequestFailure("Getting error while removing profile picture. Please try  again."));
        }
    }

    useEffect(() => {
        getCommentList();
    }, []);

    return <>
        <Card>
            <Scrollbar>
                <TableContainer
                    component={Paper}
                    sx={{ minWidth: 800, borderRadius: "0px" }}
                >
                    <Table>
                        <NewsListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={10}
                            numSelected={1}
                        />
                        <TableBody>
                            <CommentItem
                                loading={loader}
                                errorMessage={errorMessage}
                                successMessage={successMessage}
                                commentList={commentList}
                                srNo={srNo}
                                handleRouteTo={handleRouteTo}
                                handlePublished={handlePublished}
                                handleOpenMenu={handleOpenMenu}
                                searchTextDebounce={searchTextDebounce}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
            </Scrollbar>
        </Card>
        <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
                sx: {
                    p: 1,
                    width: 140,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                },
            }}
        >
            <MenuItem
                sx={{ color: "error.main" }}
                onClick={() => {
                    handleClickOpen();
                    handleCloseMenu();
                }}
            >
                <Iconify
                    icon={"eva:trash-2-outline"}
                    sx={{ mr: 2 }}
                />
                Delete
            </MenuItem>
        </Popover>

        <AlertDialog
            open={modalShow}
            setOpen={setModalShow}
            deletefunction={deleteCommentById}
            moduleType={'comment'}
        />
    </>
}

export default CommentListing;
const TABLE_HEAD = [
    { id: "Comment", className:'', label: "Comment", align: '' },
    { id: "News", className:'', label: "News", align: '' },
    { id: "status", className:'', label: "Is Approved", align: '' },
    { id: "options", className:'', label: "Options", align: 'right' },
];