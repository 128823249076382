import { Navigate, useRoutes } from "react-router-dom";
// layouts

import SimpleLayout from "./layouts/simple/SimpleLayout";

import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import DashboardAppPage from "./pages/DashboardAppPage";
import News from "./pages/News";
import CreateNews from "./pages/News/CreateNews";
import NewsBulletin from "./pages/News/NewsBulletin";
import { useLocalStorage } from "./utils/hooks/useLocalstorage";
import EditNews from "./pages/News/EditNews";
import CreateBulletin from "./pages/News/CreateBulletin";
import EditBulletin from "./pages/News/EditBulletin";
import ForgetPassword from "./pages/ForgetPassword";
import CreateUser from "./pages/Users/CreateUser";
// import { ArchievedNews } from "./pages/News/ArchivedNews";
import ArchievedNews from "./pages/ArchievedNews";
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import ProfileAdmin from "./pages/Profile";
import EditProfile from "./pages/adminProfile";
import ChangePassword from "./pages/ChangePassword";
import Podcast from "./pages/News/Podcast";
// import CreateContacts from "./pages/Contacts/CreateContacts";
import ContactPage from "./pages/Contacts/Contacts";
import EditContact from "./pages/Contacts/EditContact";
import Subscribers from "./pages/Subscribers";
import SetPassword from "./pages/resetPassword";
import GeneralSettings from "./pages/GeneralSettings";
import StaticPages from "./pages/StaticPages";
import Sponsers from "./pages/Sponsers";
import AddSponsors from "./pages/Sponsers/addNewSponser";
import EditSponsors from "./pages/Sponsers/EditSponser";

import Category from "./pages/Category";
import AddCategory from "./pages/Category/addNewCategory";
import EditCategory from "./pages/Category/EditCategory";

import Attribute from "./pages/Attribute";
import AddAttribute from "./pages/Attribute/addNewAttribute";
import EditAttribute from "./pages/Attribute/EditAttribute";

import AttributeValue from "./pages/AttributeValue";
import AddAttributeValue from "./pages/AttributeValue/addNewAttributeValue";
import EditAttributeValue from "./pages/AttributeValue/EditAttributeValue";

import AddProductParent from "./pages/Product/addProductParent";
import AddProduct from "./pages/Product/addProduct";
import Product from "./pages/Product";
import EditProduct from "./pages/Product/EditProduct";

import Comment from "./pages/Comment";

import Author from "./pages/Author";
import AddAuthor from "./pages/Author/addNewAuthor";
import EditAuthor from "./pages/Author/EditAuthor";
import Orders from "./pages/Orders/Orders"
import OrderDetail from "./pages/Orders/orderdetails/OrderDetail";
import "./default.css";
import Invoice from "./pages/Orders/invoice/Invoice";


// ----------------------------------------------------------------------

export default function Router() {
  const user = useLocalStorage();
  const routes = useRoutes([
    {
      path: "/dashboard",
      element: user ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "app", element: <DashboardAppPage /> },
        { path: "user/create-user", element: <CreateUser /> },
        { path: "profile", element: <ProfileAdmin /> },
        { path: "editProfile/:id", element: <EditProfile /> },
        { path: "changePassword/:id", element: <ChangePassword /> },
        { path: "subscribers", element: <Subscribers /> },
        { path: "contacts", element: <ContactPage /> },
        // { path: "contacts/create-contacts", element: <CreateContacts /> },
        // { path: "contacts/:id", element: <EditContact /> },
        { path: "news", element: <News /> },
        { path: "news/create-news", element: <CreateNews /> },
        { path: "news/:id", element: <EditNews /> },
        { path: "news-podcast", element: <Podcast /> },
        { path: "news-podcast/create-podcast", element: <CreateNews /> },
        { path: "news-podcast/:id", element: <EditNews /> },
        { path: "archieved-news", element: <ArchievedNews /> },
        // { path: "archieved-news/:id", element: <EditNews /> },
        { path: "news-bulletin", element: <NewsBulletin /> },
        { path: "news-bulletin/:id", element: <EditBulletin /> },
        { path: "news-bulletin/create-bulletin", element: <CreateBulletin /> },
        { path: "general-settings", element: <GeneralSettings /> },
        { path: "static-pages", element: <StaticPages /> },
        { path: "sponsors", element: <Sponsers /> },
        { path: "sponsors/:id", element: <EditSponsors /> },
        { path: "sponsors/create-sponsors", element: <AddSponsors /> },

        { path: "author", element: <Author /> },
        { path: "author/:id", element: <EditAuthor /> },
        { path: "author/create-author", element: <AddAuthor /> },

        { path: "news-comments", element: <Comment /> },
      ],
    },
    {
      path: "/e-commerce",
      element: user ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "category", element: <Category /> },
        { path: "category/:id", element: <EditCategory /> },
        { path: "category/create", element: <AddCategory /> },

        { path: "attribute", element: <Attribute /> },
        { path: "attribute/:id", element: <EditAttribute /> },
        { path: "attribute/create", element: <AddAttribute /> },

        { path: "attributevalue/:attribute_id", element: <AttributeValue /> },
        { path: "attributevalue/:attribute_id/:id", element: <EditAttributeValue /> },
        { path: "attributevalue/create/:attribute_id", element: <AddAttributeValue /> },

        { path: "product/listing", element: <Product /> },
        { path: "product/:id", element: <EditProduct /> },
        { path: "product/add_parent", element: <AddProductParent /> },
        { path: "product/add_product/:id", element: <AddProduct /> },

        { path: "orders", element: <Orders /> },
        { path: "orders/:id", element: <OrderDetail/> },

      ]
    },
    {
      path: "login",
      element: !user ? <LoginPage /> : <Navigate to="/dashboard/app" />,
    },
    {
      path: "forget-password",
      element: !user ? <ForgetPassword /> : <Navigate to="/dashboard/app" />,
    },
    {
      path: "set-password/:otp",
      element: !user ? <SetPassword /> : <Navigate to="/dashboard/app" />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
    {
      path: "/e-commerce/orders/print/:id",
      element: user ? <Invoice /> : <Navigate to="/login" />,
    },
  ]);

  return routes;
}
