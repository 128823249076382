import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AlertDialog from "../../common/AlertDialog";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import "../style.css";
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import { createNotification } from "../../common/createNotification";
// components
import Iconify from "../../components/iconify";
// sections
import UserListHead from "../../app/user/UserListHead";
import { deleteContact, getContactList } from "src/api/contactList";
import { wordsCapping } from "src/utils/helpers/functions";
import { Margin, Padding } from "@mui/icons-material";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "first_name", className: 'cu_first_name', label: "First name", alignRight: false, },
  { id: "last_name", className: 'cu_last_name', label: "Last name", alignRight: false },
  { id: "email", className: 'cu_email', label: "Email", alignRight: false },
  { id: "created_at", className: 'cu_created_at', label: "Created", alignRight: false },
  { id: "role", className: 'cu_role', label: "Number", alignRight: false },
  { id: "isemailVerified", className: 'cu_action', label: "Message", alignRight: false },
  { id: "action", className: '', label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

export default function ContactPage() {
  //constants
  const pathName = window.location.pathname.split("/")[2];
  const path = pathName.charAt(0).toUpperCase() + pathName.slice(1);
  const navigate = useNavigate();

  //states---------------------------------
  const [open, setOpen] = useState(null);
  const [order, setOrder] = useState("asc");
  const [modalShow, setModalShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [newsId, setnewsId] = useState("");
  const [counts, setCounts] = useState(0);
  const [orderBy, setOrderBy] = useState("name");
  const [isAscending, setIsAscending] = useState(false);
  const [USERLIST, setUserList] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [expandedIndex, setExpandedIndex] = useState(null);


  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  const MAX_LENGTH = 100;

  const textStyle = (isExpanded) => ({
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    padding:0,
    margin:0,
    overflow: 'hidden',
    WebkitLineClamp: isExpanded ? 'unset' : 2,
    maxHeight: isExpanded ? 'none' : '3em', // Adjust based on line height
    transition: 'max-height 0.3s ease',
  });



  //functions----------------------------------------
  const handleOpenMenu = (event, id) => {
    setnewsId(id);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    USERLIST.sort((a, b) => {
      const fa = a.first_name.toLowerCase();
      const fb = b.first_name.toLowerCase();
      if (isAscending === false) {
        if (fa < fb) {
          setIsAscending(true);
          return -1;
        }

        if (fa > fb) {
          setIsAscending(true);
          return 1;
        }
      } else if (isAscending === true) {
        if (fa > fb) {
          setIsAscending(false);
          return -1;
        }

        if (fa > fb) {
          setIsAscending(false);
          return 1;
        }
      }

      return 0;
    });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRouteTo = (path) => {
    navigate(path);
  };

  const handleDeleteById = async (id) => {
    await deleteContact(id);
    await fetchUsers();
    handleCloseMenu();
    createNotification("success", "Delete", "Contact Deleted Successfully");
  };

  const fetchUsers = async () => {
    setLoading(true);
    const res = await getContactList(page + 1, rowsPerPage);
    setUserList(res.data.data.rows);
    // console.log("data contact", res.data.data.rows)
    setCounts(res.data.data.count);
    setLoading(false);
  };

  //use Effect----------------------------------------
  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);



  //const tableRef = useRef(null);
  const handleDownload = () => {
    const workbook = XLSX.utils.book_new();
    const NewUserList = [];
    USERLIST.forEach(function (obj, key) {
      NewUserList.push({ 'First Name': obj.first_name, 'Last Name': obj.last_name, 'Email': obj.email, 'Phone Number': obj.country + obj.phone, 'Message': obj.message });
    });

    const worksheet = XLSX.utils.json_to_sheet(NewUserList);
    const wscols = TABLE_HEAD.map((column) => ({ wpx: 120 }));
    worksheet['!cols'] = wscols;

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Contacts');
    const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data, 'contacts.xlsx');
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };


  return (
    <>
      <Helmet>
        <title> {path} </title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Contact Us
          </Typography>
          <Button onClick={handleDownload}
            variant="contained"
            startIcon={<Iconify icon="bxs:file" />}
          >
            Export Contact
          </Button>
          {/* <Link
            style={{ textDecoration: "none" }}
            to={`/dashboard/${pathName}/create-${pathName}`}
          >
           <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Contact
            </Button> 
          </Link>*/}
        </Stack>

        {loading === true ? (
          <div className="loading_main">
            <div className="loading_inner">
              <CircularProgress />
            </div>
          </div>
        ) : (
          <>
            <Card>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table id="myTable">
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    numSelected={selected?.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />

                  <TableBody className="table-spacer">
                    {USERLIST.map((item, index) => (

                      <TableRow hover key={item.id} tabIndex={-1} spacing={3}>
                        <TableCell className="word-wrap cu_first_name" align="left" >
                          <Typography className="word-wrap cu_first_name" >
                            {/* {wordsCapping(item.first_name, 20)}   */}
                            {item.first_name}
                          </Typography>
                        </TableCell>
                        <TableCell className="word-wrap cu_last_name" align="left" >
                          <Typography className="word-wrap" >
                            {/* {wordsCapping(item.last_name, 20)} */}
                            {item.last_name}
                          </Typography>
                        </TableCell>
                        <TableCell className="word-wrap cu_email" align="left">{item.email}</TableCell>
                        <TableCell className="word-wrap cu_created_at" align="left">{new Date(item.createdAt).toDateString()}</TableCell>


                        <TableCell className="word-wrap cu_role" align="left">
                          {item.country} {item.phone}{" "}
                        </TableCell>
                        <TableCell className="word-wrap cu_action" align="left">
                          <p style={textStyle(expandedIndex === index) }>
                            {item.message}
                          </p>
                          {(item.message.length > MAX_LENGTH) && (
                            <span onClick={() => toggleExpand(index)} style={{ color: '#dd4b39',fontWeight: '600' }}>
                              {expandedIndex === index ? 'Read Less' : 'Read More'}
                            </span>
                          )}
                        </TableCell>

                        <TableCell align="center" className=" word-wrap">
                        <MenuItem
                              sx={{ color: "error.main" }}
                              onClick={() => setModalShow(true)}
                            >
                              <Iconify
                                icon={"eva:trash-2-outline"}
                                sx={{ mr: 2 }}
                              />
                              
                            </MenuItem>
                          {/* <Popover
                            open={Boolean(open)}
                            anchorEl={open}
                            onClose={handleCloseMenu}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            PaperProps={{
                              sx: {
                                p: 1,
                                width: 140,
                                "& .MuiMenuItem-root": {
                                  px: 1,
                                  typography: "body2",
                                  borderRadius: 0.75,
                                },
                              },
                            }}
                          >
                            <MenuItem
                              sx={{ color: "error.main" }}
                              onClick={() => setModalShow(true)}
                            >
                              <Iconify
                                icon={"eva:trash-2-outline"}
                                sx={{ mr: 2 }}
                              />
                              Delete
                            </MenuItem>
                          </Popover> */}
                          {/* <IconButton
                            size="large"
                            color="inherit"
                            onClick={(e) => handleOpenMenu(e, item.id, item)}
                          >
                            <Iconify icon={"eva:more-vertical-fill"} />
                          </IconButton> */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {USERLIST.length > 0 &&
                <TablePagination
                  rowsPerPageOptions={USERLIST.length > 0 ? [5, 10, 25, 50, 100, 500, 1000] : false}
                  component="div"
                  count={counts}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              }
            </Card>
          </>
        )}
      </Container>
      <AlertDialog
        open={modalShow}
        setOpen={setModalShow}
        deletefunction={() => handleDeleteById(newsId)}
        moduleType="contact"
      />
    </>
  );
}
